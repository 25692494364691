<template lang="pug">
  .vlist
    .vlist__item(v-for="item in items" :key="item.id"
                            :class="classMerger(item)")
        .item-content
            .item-title(@click="callRoute(item)") {{ $t('menu.'+item.title) }}
</template>

<script>
import HeaderLinks from '../HeaderLinks.vue';

export default {
  name: 'HeaderLinks',
  extends: HeaderLinks
};
</script>

<style lang="scss">
@import "@/assets/styles/top-menu/sunduk-white/header-links_sw.scss";
</style>
