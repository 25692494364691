<template lang="pug">
</template>

<script>
import {mapGetters, mapMutations} from 'vuex';

export default {
  name: 'HeaderLinks',
  data: () => ({
    items: [
      {id: 'root', title: 'home', route: ''},
      {id: 'games', title: 'games', route: 'games'},
      {id: 'promotions', title: 'promotions', route: ''},
    ],

  }),
  computed: {
    ...mapGetters({
      pages: 'main/getPages',
    })
  },
  methods: {
    ...mapMutations({
      SET_OPEN_PAGE: 'main/SET_OPEN_PAGE',
      SET_LIST: 'games/SET_LIST',
    }),
    classMerger(item) {
      return {
        'active': this.pages[item.id],
      };
    },
    async callRoute(item) {
      if (window.location.pathname !== `/${item.route}`) {
        this.SET_OPEN_PAGE(item.id);
        this.$router.push(`/${item.route}`);
        window.scrollTo(0, 0);
      }

      if (item.id === 'games') {
        this.SET_LIST([]);
      }
    },
  },
};
</script>

<style lang="scss">
</style>
